import {
    Component,
    OnInit,
} from '@angular/core';
import {
    FormBuilder,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
    SettingsResponse,
} from '#interfaces/http-response/responses/http-responses';
import {
    SettingsService,
} from '#services/settings/settings.service';
import { AlertService } from '#utils/alert-service/alert-service.service';
import {
    Observable,
    take
} from 'rxjs';

@Component({
  selector: 'app-settings-user',
  templateUrl: './settings-user.component.html',
  styles: [
  ]
})
export class SettingsUserComponent implements OnInit {
  public settingsForm = this.fbuilder.group({
    longOpportunityMonths: [1, [Validators.required, Validators.min(1), Validators.max(6)]]
  });

  public $settings: Observable<SettingsResponse>;

  constructor(private fbuilder: FormBuilder, private _settingsService: SettingsService, public matDialog: MatDialog, private _alertService: AlertService) { }

  ngOnInit(): void {
    this.$settings = this._settingsService.settings$
    this._settingsService.Get().pipe(take(1)).subscribe(({ data }) => {
      this.settingsForm.controls.longOpportunityMonths.setValue(data.schedule ?? 0)
    });
  }

  public saveChanges() {
    const { longOpportunityMonths } = this.settingsForm.controls
    const currentUserId = Buffer.from(localStorage.getItem('idUser'), 'base64').toString();
    return this._settingsService.UpdateSchedules(currentUserId, longOpportunityMonths.value).subscribe({
      next: ({ message }) => {
        this._alertService.saveAlert(message)
        this.matDialog.closeAll();
      },
      error: () => {
        this._alertService.errorAlert([], 'Se ha producido un error al guardar los cambios, revisa que hayas puesto las opciones correctamente.')
      }
    })
  }
}
