import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class CleanStringsInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedReq = req.clone();

    if ((req.method === "POST" || req.method === "PUT" || req.method === "DELETE") && req.body) {
      Object.keys(req.body).forEach((key) => {
        if (req.body[key] === "") {
          modifiedReq = modifiedReq.clone({
            body: { ...req.body, [key]: null },
          });
        }

        if (req.body[key] == undefined) {
          delete req.body[key];
        }
      });
    }

    return next.handle(modifiedReq);
  }
}
