import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AppVersionService {
    public async update() {
        this.setlocalVersion(await this.remoteVersion());
    }

    public async outdated() {
        if (!this.#parseVersions().length) return true;

        return (await this.remoteVersion()) !== this.localVersion;
    }

    public async remoteVersion() {
        // TODO: Wait for API
        return this.localVersion;
    }

    public check(v: string) {
        return this.#parseVersions().includes(v);
    }

    public get localVersion(): string {
        return this.#parseVersions().slice().pop() ?? environment.version;
    }

    public setlocalVersion(v: string) {
        this.#setVersion(v);
    }

    #parseVersions(): string[] {
        const versions = localStorage.getItem('versions');
        if (!versions) localStorage.setItem('versions', '[]');

        return JSON.parse(versions ?? '[]');
    }

    #setVersion(v: string) {
        const versions = this.#parseVersions();
        versions.push(v);
        localStorage.setItem('versions', JSON.stringify(versions));
    }
}