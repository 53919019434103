import { Component } from '@angular/core';

@Component({
    template: `
        <div class="flex flex-col space-y-2 items-center justify-center h-full w-full">
            <mat-spinner></mat-spinner>
            <span class="text-center font-bold text-2xl">Procesando datos...</span>
            <small class="text-center">Espere un momento por favor</small>
        </div>
    `
})
export class LoadingDialogComponent {
}
