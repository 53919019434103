import {
    HttpClient,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    SettingsResponse,
    UpdateSettingsResponse,
} from '#interfaces/http-response/responses/http-responses';
import { HeadersHttpService } from '#utils/http-headers/http-headers.service';
import { environment } from 'environments/environment';
import {
    BehaviorSubject,
    Observable,
    catchError,
    tap,
    throwError
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private _roles: BehaviorSubject<UpdateSettingsResponse> = new BehaviorSubject({} as any);
  private _settings: BehaviorSubject<SettingsResponse> = new BehaviorSubject({} as any);

  baseUrl: string = environment.baseUrl + "OpportunitySchedules/";
  constructor(private httpClient: HttpClient, private headerService: HeadersHttpService) { }

  get roles$(): Observable<UpdateSettingsResponse> {
    return this._roles.asObservable();
  }

  get settings$(): Observable<SettingsResponse> {
    return this._settings.asObservable();
  }


  UpdateSchedules(userId: string, days: number): Observable<UpdateSettingsResponse> {
    return this.httpClient.post<UpdateSettingsResponse>(this.baseUrl + 'UpdateSchedule', { userId, option: days }, { headers: this.headerService.getHeadersPrivate() })
      .pipe(
        tap((resp) => {

          this._roles.next(resp);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse)
        })
      );
  }

  Get(): Observable<SettingsResponse> {
    return this.httpClient.get<SettingsResponse>(this.baseUrl + 'GetByUserId', { headers: this.headerService.getHeadersPrivate() })
      .pipe(
        tap((resp) => {
          this._settings.next(resp);
        }), catchError(err => {
          return throwError(() => err as HttpErrorResponse)
        })
      );
  }
}
