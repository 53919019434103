// -----------------------------------------------------------------------------------------------------

export class AuthUtils {
    /**
     * Constructor
     */
    constructor() {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Is token expired?
     *
     * @param token
     * @param offsetSeconds
     */
    static isTokenExpired(token: string, offsetSeconds?: number): boolean {

        // Return if there is no token
        if (!token || token === '') {
            return false;
        }
        // Get the expiration date
        const date = new Date(localStorage.getItem('expirationDate'));

        offsetSeconds = offsetSeconds || 0;

        if (date === null) {
            return true;
        }
        const value = !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
        // Check if the token is expired
        return value;
    }


    
}
