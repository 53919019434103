<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden group"
  [appearance]="navigationAppearance"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 p-6 pb-0 mb-6 sm-">
      <img
        [ngClass]="{ 'w-20': isLogoIso, 'w-50': !isLogoIso }"
        class="hidden dark:flex m-auto max-h-20"
        src="{{ logo }}"
        alt="Logo image"
      />
    </div>
    <form
      [formGroup]="form"
      [ngClass]="{
        'opacity-100': navigationAppearance === 'default',
        'opacity-0 group-hover:opacity-100': navigationAppearance === 'dense'
      }"
      class="pt-4 px-4 transition-all duration-300 flex gap-x-4 relative items-center"
    >
      <div class="flex relative items-center justify-end" (mouseleave)="onInputMouseLeave()">
        <input
        #searchInput
          id="search-bar-crm"
          placeholder="Buscar en el menu..."
          formControlName="search"
          class="rounded-sm bg-black text-lg py-2 pr-6 px-1 text-white border-b-2 border-b-blue-500 mr-auto"
          matInput
          type="text"
          
        />
        <mat-icon
          class="absolute z-10 pr-2 h-8 w-8 text-blue-500"
          [svgIcon]="'heroicons_outline:search'"
        ></mat-icon>
      </div>
    </form>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <div class="flex items-center pr-2 space-x-2">
      <!-- Navigation toggle button -->
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
      <!-- Navigation appearance toggle button -->
      <button
        class="hidden md:inline-flex"
        mat-icon-button
        (click)="toggleNavigationAppearance()"
      >
        <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
      </button>
      <button
        *ngIf="config?.scheme === 'dark'"
        class="inline-flex"
        mat-icon-button
        (click)="setScheme('light')"
      >
        <mat-icon>light_mode</mat-icon>
      </button>
      <button
        *ngIf="config?.scheme === 'light'"
        class="inline-flex"
        mat-icon-button
        (click)="setScheme('dark')"
      >
        <mat-icon>dark_mode</mat-icon>
    </button>
    </div>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <button
      mat-stroked-button
        color="white"
        class=" text-primary ml-auto md:ml-4 sm:min-w-[200px] sm:ml-0 grow sm:grow-0"
        *ngIf="!isPurchaseAdvisor"
        (click)="downloadUserManual()"
      >
      <mat-icon class="mr-1 text-primary">download</mat-icon>
        Descargar Manual

      </button>
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="pt-1 relative flex flex-0 items-center lg:flex-row flex-col lg:justify-between w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="font-medium text-secondary">
      Distribuidora Platino S.A. de C.V. &copy; {{ currentYear }}
    </span>
    <span class="font-medium text-secondary">
      Platino Software &copy; | v{{ appVersion }}
    </span>
  </div>
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
