import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class HeadersHttpService {
  public getHeadersPublic() {
    return new HttpHeaders({
      "Content-Type": "application/json",
    });
  }
  public getHeadersPrivate(
    payload: { showModal: boolean; lazyGet?: boolean } = {
      showModal: true,
      lazyGet: false,
    }
  ) {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${atob(localStorage.getItem("accessToken"))}`,
      showModal: payload.showModal ? "yes" : "no",
      lazyGet: payload.lazyGet? "yes" : "no",
    });
  }
  public getHeadersFormDataPrivate() {
    return new HttpHeaders({
      "Content-Disposition": "multipart/form-data",
      Authorization: `Bearer ${atob(localStorage.getItem("accessToken"))}`,
    });
  }
}