export enum Roles {
  SuperAdmin = "SuperAdmin",
  Admin = "Admin",
  Manager = "Manager",
  AfterSale = "AfterSale",
  SalesAdvisor = "SalesAdvisor",
  CommunityManager = "CommunityManager",
  OfficeSalesAdvisor = "OfficeSalesAdvisor",
  CreditManager = "CreditManager",
  ReportAnalyst = "ReportAnalyst",
}
