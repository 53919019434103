import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';

import { NavigationService } from '#root/core/navigation/navigation.service';
import {
  forkJoin,
  Observable
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    //this._signalRService.startConnection();
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
      this._navigationService.get(),
    ]);
  }
}
